import { mapState, mapActions } from 'vuex'
import TDashboardGeneralInfo from '@/components/dashboard/general-info.vue'
import TDashboardDonationsChart from '@/components/dashboard/donations-chart.vue'
import TRecurrenceFrictionRate from '@/components/dashboard/recurrence-friction-rate.vue'
import TDashboardObjectiveCard from '@/components/dashboard/objective-card'

export default {
  name: 'TDashboardPage',
  title: ({ title }) => title,
  data () {
    return {
      title: this.$t('Dashboard'),
      error: false
    }
  },
  computed: {
    ...mapState({
      isLoading: ({ dashboard }) => dashboard.loadingGeneralData
    })
  },
  methods: {
    ...mapActions('breadcrumb', [ 'changeBreadcrumbItems' ]),
    ...mapActions('dashboard', [
      'updateGeneralData',
      'updateTotalDonations',
      'updateCohortData'
    ]),
    bindjQueryPlugins () {
      $('.tui.dropdown').dropdown()
      $('.tui.popup').popup({
        transition: 'fade'
      })
    },
    updateStatusBar (period = 7) {
      this.updateGeneralData({ period })
        .catch(err => this.$log.error('error', err))
    }
  },
  mounted () {
    // this functionality is not working yet and there are no data available, to prevent the console errors, it is commented
    // const { generalData, totalDonations, cohortData } = this.$store.state.dashboard
    // const { leftItems, rightItems } = generalData
    // const { total, statusBar, chartData } = totalDonations

    this.bindjQueryPlugins()
    // this.changeBreadcrumbItems()

    // if (!leftItems.length && !rightItems.length) {
    //   this.updateGeneralData()
    //     .catch(err => this.$log.error('error', err))
    // }

    // if (!total.length && !statusBar.length && !chartData.length) {
    //   this.updateTotalDonations()
    //     .catch(err => this.$log.error('error', err))
    // }

    // if (!cohortData.length) {
    //   this.updateCohortData()
    //     .catch(err => this.$log.error('error', err))
    // }
  },
  components: {
    TDashboardGeneralInfo,
    TDashboardDonationsChart,
    TRecurrenceFrictionRate,
    TDashboardObjectiveCard
  }
}

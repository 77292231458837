<template>
  <div class="tui tuim ui container general-info">
    <t-status-color-bar v-if="isLoading" :loading="isLoading" />
    <t-status-color-bar v-else :items="generalData.statusBar" />
    <div class="tui ui grid">
      <div class="items left floated left aligned eight wide column">
        <t-status-bar-item :loading="isLoading" v-if="isLoading" class="loading" />
        <t-status-bar-item :loading="isLoading" v-if="isLoading" class="loading" />
        <t-status-bar-item :loading="isLoading" v-if="isLoading" class="loading" />
        <t-status-bar-item :loading="isLoading" v-if="isLoading" class="loading" />
        <t-status-bar-item v-for="(leftItem, k) in generalData.leftItems" :key="k" :item="leftItem" />
      </div>
      <div class="items right floated right aligned eight wide column">
        <t-status-bar-item :loading="isLoading" v-if="isLoading" class="loading" />
        <t-status-bar-item :loading="isLoading" v-if="isLoading" class="loading" />
        <t-status-bar-item :loading="isLoading" v-if="isLoading" class="loading" />
        <t-status-bar-item v-for="(rightItem, i) in generalData.rightItems" :key="i" :item="rightItem" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TStatusColorBar from '@/components/status/color-bar.vue'
import TStatusBarItem from '@/components/status/item.vue'

export default {
  name: 'TDashboardGeneralInfo',
  computed: {
    ...mapState({
      isLoading: ({ dashboard }) => dashboard.loadingGeneralData,
      generalData: ({ dashboard }) => dashboard.generalData
    })
  },
  components: {
    TStatusColorBar,
    TStatusBarItem
  }
}
</script>

<style lang="scss">
.general-info {
  .items {
    .loading {
      width: 100%;

      .tui.tuim.ui.placeholder {
        &:first-child { margin-top: 10px; }
      }
    }

    .tui.tuim.item.status-item span.value {
      font-size: 27px;
    }
  }

  .ui.grid > .column:not(.row),
  .ui.grid > .row > .column {
    &.items {
      align-items: flex-start;
      display: flex;
      padding: 20px;

      &.left {
        justify-content: flex-start;

        .tui.tuim.item.status-item {
          margin-left: 0;
          margin-right: 20px;
        }
      }

      &.right {
        justify-content: flex-end;
      }
    }
  }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "donations-chart" },
    [
      _c(
        "t-status-bar",
        {
          attrs: {
            title: "<strong>" + _vm.donationsData.total + "</strong> doações"
          }
        },
        _vm._l(_vm.donationsData.statusBar, function(item, k) {
          return _c("t-status-bar-item", { key: k, attrs: { item: item } })
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "donations-chart-bar" },
        [
          Object.keys(_vm.donationsData.chartData).length
            ? _c("t-bar-chart", {
                staticClass: "tui tuim chart bar",
                attrs: {
                  styles: { height: "350px" },
                  chartData: _vm.donationsData.chartData,
                  options: _vm.chartOptions
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
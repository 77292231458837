<template>
  <div class="donations-chart">
    <t-status-bar :title="`<strong>${donationsData.total}</strong> doações`">
      <t-status-bar-item
        v-for="(item, k) in donationsData.statusBar"
        :key="k"
        :item="item"
      />
    </t-status-bar>

    <div class="donations-chart-bar">
      <t-bar-chart
        :styles="{ height: '350px' }"
        v-if="Object.keys(donationsData.chartData).length"
        class="tui tuim chart bar"
        :chartData="donationsData.chartData"
        :options="chartOptions"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TStatusBar from '@/components/status/bar'
import TStatusBarItem from '@/components/status/item.vue'

export default {
  name: 'TDashboardDonationsChart',
  data () {
    return {
      chartOptions: {
        tooltips: {
          titleFontFamily: 'Sarabun',
          bodyFontFamily: 'Sarabun',
          footerFontFamily: 'Sarabun',
          titleFontColor: '#474747',
          bodyFontColor: '#474747',
          backgroundColor: '#ffffff',
          mode: 'index',
          titleSpacing: 6,
          bodySpacing: 6,
          xPadding: 15,
          yPadding: 15,
          borderWidth: 0,
          intersect: true,
          displayColors: false,
          // enabled: false,
          callbacks: {
            label: (tooltip, data) => {
              // const { label, backgroundColor } = data.datasets[tooltip.datasetIndex]
              const { label } = data.datasets[tooltip.datasetIndex]
              // return `<span>${backgroundColor}</span> ${label}: ${tooltip.yLabel}`
              return `${label}: ${tooltip.yLabel}`
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapState({
      isLoading: ({ dashboard }) => dashboard.loadingTotalDonations,
      donationsData: ({ dashboard }) => dashboard.totalDonations
    })
  },
  components: {
    TStatusBar,
    TStatusBarItem
  }
}
</script>

<style lang="scss" scoped>
.donations-chart {
  width: 100%;
  margin-bottom: 60px;

  .tui.tuim.ui.card.status-bar,
  .tui.tuim.chart.bar {
    margin: 0;
  }

  .tui.tuim.ui.card.status-bar {
    // border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .donations-chart-bar {
    position: relative;
    background-color: $white-1;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 47px;
      pointer-events: none;
      background-color: #fff;
      border-top: $border;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }

    .tui.tuim.chart.bar {
      padding: 15px 0;
      border: $border;
      border-top: 0;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      position: relative;
      z-index: 2;
    }
  }

  h2 {
    font-weight: 800;
    font-size: 27px;
    line-height: 1.2;
    color: $black-0;
    margin-bottom: 40px;
  }
}
</style>

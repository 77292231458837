var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.cohortData.length
    ? _c(
        "div",
        { staticClass: "recurrence-friction-rate" },
        [
          _c("t-chort-chart", {
            attrs: { cohortData: _vm.cohortData, initialDate: _vm.initialDate }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
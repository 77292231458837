var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("t-page-header", {
        attrs: {
          title: _vm.$t("Dashboard"),
          popup:
            "<div class='content'><p>O Painel de Controle exibe um resumo financeiro com os valores processados com sucesso, os que tiveram falhas leves, falhas graves e os que foram estornados.</p><p>Além disso, o Painel de Controle informa a quantidade de contestações, de operações processadas e, mais importante, a taxa de efetividade da coleta de valores.</p></div>"
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "button",
                  {
                    staticClass:
                      "tui tuim ui basic pointing top right dropdown icon button",
                    attrs: { disabled: _vm.isLoading }
                  },
                  [
                    _c("span", [_vm._v("Filtrar por")]),
                    _c("t-icon", {
                      staticClass: "tui tuim icon",
                      attrs: { icon: ["fas", "caret-down"] }
                    }),
                    _c(
                      "div",
                      { staticClass: "menu" },
                      _vm._l([7, 30, 60, 90], function(period, i) {
                        return _c("div", {
                          key: i,
                          staticClass: "item",
                          domProps: { textContent: _vm._s(period + " dias") },
                          on: {
                            click: function($event) {
                              return _vm.updateStatusBar(period)
                            }
                          }
                        })
                      }),
                      0
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "bottom",
            fn: function() {
              return [_c("t-dashboard-general-info")]
            },
            proxy: true
          }
        ])
      }),
      _c("t-page-main", { staticClass: "dashboard" }, [
        _c(
          "section",
          { staticClass: "dashboard-section sixteen column" },
          [
            _c("h2", { staticClass: "dashboard-title" }, [
              _c("span", [_vm._v("Total de doações")]),
              _c(
                "span",
                {
                  staticClass: "tui popup",
                  attrs: {
                    "data-html":
                      "<div class='content'>O Total de Doações é um gráfico de barras que exibe dados quantitativos sobre todas as doações em um determinado período, que você pode filtrar por semana, mês, bimestre e por ano. Ele informa doações ativas e inativas, pausadas e canceladas.</div>"
                  }
                },
                [_c("t-icon", { attrs: { icon: ["far", "question-circle"] } })],
                1
              )
            ]),
            _c("t-dashboard-donations-chart")
          ],
          1
        ),
        false
          ? _c("section", { staticClass: "dashboard-section sixteen column" }, [
              _c("h2", { staticClass: "dashboard-title" }, [
                _c("span", [_vm._v("Meus objetivos")]),
                _c(
                  "span",
                  {
                    staticClass: "tui popup",
                    attrs: {
                      "data-html":
                        "<div class='content'>Meus Objetivos é uma funcionalidade em que você pode criar metas para sua organização e definir prazos para a conclusão delas. É totalmente customizável e você pode editá-los depois de sua criação.</div>"
                    }
                  },
                  [
                    _c("t-icon", {
                      attrs: { icon: ["far", "question-circle"] }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "ui grid" }, [
                _c(
                  "div",
                  { staticClass: "ui four wide column" },
                  [
                    _c("t-dashboard-objective-card", {
                      attrs: {
                        title: "Doações mensais",
                        width: "60%",
                        backgroundColor: "#b4db75",
                        leftMeta: { value: "200.719", currency: "BRL" },
                        rightMeta: { value: "300.719", currency: "BRL" }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "ui four wide column" },
                  [
                    _c("t-dashboard-objective-card", {
                      attrs: {
                        title: "Doações únicas",
                        width: "75%",
                        backgroundColor: "#fd9093",
                        leftMeta: { value: "200.719", currency: "BRL" },
                        rightMeta: { value: "300.719", currency: "BRL" }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "ui four wide column" },
                  [
                    _c("t-dashboard-objective-card", {
                      attrs: {
                        title: "Doações recorrentes",
                        width: "50%",
                        backgroundColor: "#f9d96a",
                        leftMeta: { value: "719" },
                        rightMeta: { value: "719" }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "ui four wide column" },
                  [
                    _c("t-dashboard-objective-card", {
                      attrs: {
                        title: "Doadores",
                        width: "80%",
                        backgroundColor: "#78e5a4",
                        leftMeta: { value: "719" },
                        rightMeta: { value: "719" }
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          : _vm._e(),
        _c(
          "section",
          { staticClass: "dashboard-section sixteen column" },
          [
            _c("h2", { staticClass: "dashboard-title" }, [
              _c("span", [_vm._v("Taxa de atrito de recorrência")]),
              _c(
                "span",
                {
                  staticClass: "tui popup",
                  attrs: {
                    "data-html":
                      "<div class='content'>A Taxa de Atrito de Recorrências é um Coorte que monitora uma quantidade de pessoas que se tornaram doadores recorrentes ao longo dos meses. Com ele, você vai poder visualizar a porcentagem de pessoas que cancelaram suas assinaturas e facilitar a tomada de decisão.</div>"
                  }
                },
                [_c("t-icon", { attrs: { icon: ["far", "question-circle"] } })],
                1
              )
            ]),
            _c("t-recurrence-friction-rate")
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
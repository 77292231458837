var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tui tuim ui card objective" }, [
    _c(
      "div",
      { staticClass: "content spacer" },
      [
        _vm.title
          ? _c(
              "h3",
              { staticClass: "header" },
              [
                _c("span", { domProps: { textContent: _vm._s(_vm.title) } }),
                _c("t-icon", {
                  staticClass: "edit",
                  attrs: { icon: ["fas", "edit"] }
                })
              ],
              1
            )
          : _vm._e(),
        _c("t-status-color-bar", {
          attrs: {
            items: [{ width: _vm.width, backgroundColor: _vm.backgroundColor }]
          }
        }),
        _c("div", { staticClass: "ui grid" }, [
          Object.keys(_vm.leftMeta).length
            ? _c("div", { staticClass: "left aligned eight wide column" }, [
                _c("span", { staticClass: "meta" }, [_vm._v("Hoje")]),
                _vm.rightMeta.value
                  ? _c("strong", {
                      domProps: { textContent: _vm._s(_vm.rightMeta.value) }
                    })
                  : _vm._e(),
                _vm.leftMeta.currency
                  ? _c("span", {
                      staticClass: "meta",
                      domProps: { textContent: _vm._s(_vm.leftMeta.currency) }
                    })
                  : _vm._e()
              ])
            : _vm._e(),
          Object.keys(_vm.rightMeta).length
            ? _c("div", { staticClass: "right aligned eight wide column" }, [
                _c("span", { staticClass: "meta" }, [_vm._v("Meta")]),
                _vm.rightMeta.value
                  ? _c("strong", {
                      domProps: { textContent: _vm._s(_vm.rightMeta.value) }
                    })
                  : _vm._e(),
                _vm.rightMeta.currency
                  ? _c("span", {
                      staticClass: "meta",
                      domProps: { textContent: _vm._s(_vm.rightMeta.currency) }
                    })
                  : _vm._e()
              ])
            : _vm._e()
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tui tuim ui container general-info" },
    [
      _vm.isLoading
        ? _c("t-status-color-bar", { attrs: { loading: _vm.isLoading } })
        : _c("t-status-color-bar", {
            attrs: { items: _vm.generalData.statusBar }
          }),
      _c("div", { staticClass: "tui ui grid" }, [
        _c(
          "div",
          { staticClass: "items left floated left aligned eight wide column" },
          [
            _vm.isLoading
              ? _c("t-status-bar-item", {
                  staticClass: "loading",
                  attrs: { loading: _vm.isLoading }
                })
              : _vm._e(),
            _vm.isLoading
              ? _c("t-status-bar-item", {
                  staticClass: "loading",
                  attrs: { loading: _vm.isLoading }
                })
              : _vm._e(),
            _vm.isLoading
              ? _c("t-status-bar-item", {
                  staticClass: "loading",
                  attrs: { loading: _vm.isLoading }
                })
              : _vm._e(),
            _vm.isLoading
              ? _c("t-status-bar-item", {
                  staticClass: "loading",
                  attrs: { loading: _vm.isLoading }
                })
              : _vm._e(),
            _vm._l(_vm.generalData.leftItems, function(leftItem, k) {
              return _c("t-status-bar-item", {
                key: k,
                attrs: { item: leftItem }
              })
            })
          ],
          2
        ),
        _c(
          "div",
          {
            staticClass: "items right floated right aligned eight wide column"
          },
          [
            _vm.isLoading
              ? _c("t-status-bar-item", {
                  staticClass: "loading",
                  attrs: { loading: _vm.isLoading }
                })
              : _vm._e(),
            _vm.isLoading
              ? _c("t-status-bar-item", {
                  staticClass: "loading",
                  attrs: { loading: _vm.isLoading }
                })
              : _vm._e(),
            _vm.isLoading
              ? _c("t-status-bar-item", {
                  staticClass: "loading",
                  attrs: { loading: _vm.isLoading }
                })
              : _vm._e(),
            _vm._l(_vm.generalData.rightItems, function(rightItem, i) {
              return _c("t-status-bar-item", {
                key: i,
                attrs: { item: rightItem }
              })
            })
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
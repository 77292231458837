<template>
  <div class="tui tuim ui card objective">
    <div class="content spacer">
      <h3 class="header" v-if="title">
        <span v-text="title" />
        <t-icon class="edit" :icon="[ 'fas', 'edit' ]" />
      </h3>
      <t-status-color-bar
        :items="[{ width, backgroundColor }]"
      />
      <div class="ui grid">
        <div v-if="Object.keys(leftMeta).length" class="left aligned eight wide column">
          <span class="meta">Hoje</span>
          <strong v-if="rightMeta.value" v-text="rightMeta.value" />
          <span class="meta" v-if="leftMeta.currency" v-text="leftMeta.currency" />
        </div>
        <div v-if="Object.keys(rightMeta).length" class="right aligned eight wide column">
          <span class="meta">Meta</span>
          <strong v-if="rightMeta.value" v-text="rightMeta.value" />
          <span class="meta" v-if="rightMeta.currency" v-text="rightMeta.currency" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TStatusColorBar from '@/components/status/color-bar.vue'

export default {
  name: 'TDashboardObjectiveCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '0%'
    },
    backgroundColor: {
      type: String,
      default: '#78e5a4'
    },
    leftMeta: {
      type: Object,
      default: () => ({})
    },
    rightMeta: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    TStatusColorBar
  }
}
</script>

<style lang="scss">
.tui.tuim.ui.card.objective {
  min-height: 160px;

  .header {
    font-size: 18px;
    line-height: 18px;
    color: $black-0;
    margin: 0 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .edit {
      color: #d1d1d1;
      cursor: pointer;
      height: 20px;
      width: 20px;
    }
  }

  .tui.tuim.status-bar.color {
    margin-bottom: 10px;
  }

  .meta {
    display: block;
    color: #7a7a7a;
    opacity: 0.5;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    font-weight: 400;

    + strong {
      font-size: 18px;
      line-height: 18px;
    }
  }
}
</style>

<template>
  <div v-if="cohortData.length" class="recurrence-friction-rate">
    <t-chort-chart
      :cohortData="cohortData"
      :initialDate="initialDate"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TRecurrenceFrictionRate',
  computed: {
    ...mapState({
      isLoading: ({ dashboard }) => dashboard.loadingCohortData,
      cohortData: ({ dashboard }) => dashboard.cohortData,
      initialDate: ({ dashboard }) => dashboard.cohortInitialDate
    })
  }
}
</script>
